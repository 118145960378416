<template>
	<div>
		<v-card flat>
			<v-list two-line>
				<template v-for="(item, index) in list">
					<v-list-item :key="index + 'a'">
						<v-list-item-avatar color="greyLighten2">
							<v-img
								v-if="item.avatar && !item.avatar.includes('female1')"
								:src="`${$environments.base_api_url}${item.avatar}`"
							></v-img>
							<span v-else class="text-h6">{{
								(item.project_title
									? item.project_title
									: $t("app.research_project"))[0]
							}}</span>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title class="font-weight-bold">
								{{
									(item.project_title
										? item.project_title
										: $t("app.research_project")) | capitalize
								}}
							</v-list-item-title>
							<v-list-item-subtitle>
								<b> {{ $t("app.language") }}</b> - {{ item.user_lang }}
							</v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-action>
							<v-chip
								v-if="item.default_profile"
								small
								color="transparent"
								class="subtitle-1"
							>
								{{ $t("app.default") }}
								<v-icon right color="success" size="18"
									>$vuetify.icons.values.check_circle</v-icon
								>
							</v-chip>
						</v-list-item-action>
					</v-list-item>
					<v-divider
						v-if="index + 1 < list.length"
						:key="index"
						inset
						class="mb-2"
					></v-divider>
				</template>
			</v-list>
		</v-card>
	</div>
</template>

<script>
import { sortArrayOfObjects } from "@/utils/helpers";
import { mapState } from "vuex";

export default {
	name: "ProjectsList",

	computed: {
		...mapState({
			projects: (state) => state.user.projects,
		}),

		list() {
			return sortArrayOfObjects(this.projects, "default_profile", true);
		},
	},
};
</script>
