<template>
	<v-card flat height="100%">
		<v-list-item-group>
			<v-list-item
				class="px-0"
				v-for="item in locales"
				:key="item.value"
				:value="item.value"
				@click="selected_locale = item.code"
			>
				<v-list-item-icon>
					<v-icon :color="item.code === selected_locale ? 'success' : ''">
						{{
							$vuetify.icons.values[
								item.code === selected_locale
									? "check_circle"
									: "circle_outline"
							]
						}}
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title v-text="$t(item.name)"></v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list-item-group>

		<v-card-actions>
			<AppButton
				label="app.save"
				icon="save"
				:class="['mt-10']"
				@click="updateLocale()"
			></AppButton>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
	name: "LocaleSwitcher",

	data() {
		return {
			selected_locale: this.$store.getters["user/getLanguage"],
		};
	},

	computed: {
		...mapState({
			locales: (state) => state.globals.locales,
		}),
		...mapGetters({
			userProfile: "user/getProfile",
		}),
	},

	methods: {
		async updateLocale() {
			try {
				this.$loader.start();

				// Update locale of user at the backend side
				await this.$store.dispatch("user/updateProfile", {
					user_lang: this.selected_locale,
					user_id: this.userProfile.id,
				});

				// Close he dialog box.
				this.$emit("close");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
