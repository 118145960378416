<template>
	<v-container fluid class="px-15 py-10">
		<v-row no-gutters class="full-height-wr">
			<!-- COL 1 -->
			<v-col sm="3">
				<v-card height="100%" class="elevation-5">
					<!-- Profile image -->
					<ProfileImage></ProfileImage>
					<!-- Options -->
					<v-list-item-group
						v-model="selectedItem"
						active-class="font-weight-bold"
						:ripple="false"
						class="mt-2"
					>
						<template v-for="(item, index) in eligibleItems">
							<v-list-item
								v-if="item.condition"
								:key="index"
								:style="
									selectedItem == index
										? {
												[$vuetify.rtl
													? 'border-left'
													: 'border-right']: '5px solid var(--v-amberDarken1-base)',
										  }
										: {}
								"
								:class="['py-1 ps-6']"
							>
								<v-list-item-icon>
									<v-icon v-text="$vuetify.icons.values[item.icon]"></v-icon>
								</v-list-item-icon>
								<v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
							</v-list-item>
						</template>
					</v-list-item-group>
				</v-card>
			</v-col>
			<!-- COL 2 -->
			<v-col>
				<!-- Heading -->
				<v-card height="100%" flat class="wr_grey_1 ms-6 px-5">
					<v-card height="9.5%" flat class="wr_grey_1">
						<v-card-text class="pt-0">
							<v-row>
								<v-col
									sm="9"
									class="headline greyDarken4--text font-weight-bold"
								>
									{{ $t("app.settings.title") }}&ensp;
									<v-icon size="30">
										{{ $vuetify.icons.values.right_chevron }} </v-icon
									>&ensp;
									{{ $t(eligibleItems[selectedItem]["title"]) }}
								</v-col>
								<v-col>
									<TheLogout></TheLogout>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
					<!-- Respected component -->
					<v-card
						height="92%"
						max-height="82vh"
						outlined
						class="px-10 pt-10 overflow-y-auto"
					>
						<component
							:is="eligibleItems[selectedItem]['component']"
						></component>
					</v-card>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { filterArray } from "@/utils/helpers";
import TheLogout from "@/components/layout/TheLogout.vue";
import * as SettingsComponents from "@/components/shared/settings/index.js";

export default {
	name: "UserSettings",

	data() {
		return {
			selectedItem: 0,
		};
	},

	components: {
		TheLogout,
		...SettingsComponents,
	},

	computed: {
		...mapGetters({
			isAdmin: "user/isAdmin",
			isStudent: "user/isStudent",
		}),

		items() {
			return [
				{
					condition: true,
					title: "app.personal_info",
					component: "PersonalInfo",
					icon: "user",
				},
				{
					condition: this.isAdmin || this.isStudent,
					title: "app.manage_projects",
					component: "Projects",
					icon: "apps",
				},
				{
					condition: this.isAdmin,
					title: "app.change_language",
					component: "LocaleSwitcher",
					icon: "language",
				},
				{
					condition: true,
					title: "app.reset_password",
					component: "ResetPassword",
					icon: "lock",
				},
			];
		},

		eligibleItems() {
			return filterArray(this.items, "condition", true, true);
		},
	},

	mounted() {
		this.getLocales();
	},

	methods: {
		async getLocales() {
			try {
				this.$loader.start();

				// Get all languages and set in state
				await this.$store.dispatch("globals/getLocales");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped>
>>> .v-list-item--active:before {
	opacity: 0.04 !important;
}
</style>
