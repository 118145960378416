<template>
	<v-card flat height="100%">
		<ProjectsList></ProjectsList>

		<v-card-actions v-if="projects && projects.length > 1">
			<AppButton
				label="app.change_default"
				:icon="$vuetify.rtl ? 'left_chevron' : 'right_chevron'"
				:class="['mt-10']"
				@click="pushToProjectsRoute()"
			></AppButton>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ProjectsList from "@/components/shared/settings/project/List.vue";

export default {
	name: "SettingsProjectIndex",

	components: {
		ProjectsList,
	},

	computed: {
		...mapState({
			projects: (state) => state.user.projects,
		}),
	},

	mounted() {
		this.listProjects();
	},

	methods: {
		async listProjects() {
			try {
				this.$loader.start();

				await this.$store.dispatch("user/listProjects");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		pushToProjectsRoute() {
			try {
				this.$loader.start();

				this.$router.push({
					name: "Projects",
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
