<template>
	<v-card flat v-if="userProfile">
		<v-card-text id="profile-wr" align="center">
			<Uploader
				param_name="avatar"
				:upload_url="uploadUrl()"
				:document_url="avatarUrl()"
				height="170px"
				@upload-success="onUploadSuccess"
				@uploaded-document-removed="onAfterUploadRemoved()"
			>
				<template v-slot:dropzone-display-content>
					<v-card
						flat
						width="170"
						height="170"
						class="greyLighten4 rounded-circle border-wr"
					>
						<v-container fill-height fluid>
							<v-row justify="center">
								<v-col align="center">
									<v-card-text>
										<v-img
											:src="require('@/assets/images/upload.png')"
											width="45"
										>
										</v-img>
									</v-card-text>
									<v-card-text
										v-html="$t('app.upload_avatar')"
										class="pt-0 subtitle-1 greyDarken4--text"
									>
									</v-card-text>
								</v-col>
							</v-row>
						</v-container>
					</v-card>
				</template>
			</Uploader>
		</v-card-text>

		<v-card-text align="center">
			<div class="text-h6 font-weight-bold greyDarken4--text text-uppercase">
				{{ fullName() }}
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Uploader from "@/components/plugins/Uploader.vue";

export default {
	name: "ProfileImage",

	data() {
		return {
			base_api_url: this.$environments.base_api_url,
		};
	},

	components: {
		Uploader,
	},

	computed: {
		...mapGetters({
			userProfile: "user/getProfile",
			fullName: "user/fullName",
		}),
	},

	methods: {
		avatarUrl() {
			let avatar = this.userProfile.avatar;
			return avatar && !avatar.includes("female1")
				? this.base_api_url + avatar
				: "";
		},

		uploadUrl() {
			return `${this.base_api_url}/user/detail/${this.userProfile.id}/`;
		},

		onUploadSuccess(response) {
			this.$store.commit("user/SET_USER", response.response);
		},

		async onAfterUploadRemoved() {
			try {
				this.$loader.start();

				await this.$store.dispatch("user/updateProfile", {
					user_id: this.userProfile.id,
					avatar: null,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped>
.border-wr {
	border: 3px dashed lightgrey !important;
}
</style>
