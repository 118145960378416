<template>
	<v-card flat height="100%">
		<v-form ref="form">
			<!-- New password -->
			<div class="label-wr">{{ $t("app.new_password") }}</div>
			<v-text-field
				outlined
				color="greenAccent2"
				v-model="new_password"
				:rules="[rules.required, rules.strongPassword]"
				:type="show_new_password ? 'text' : 'password'"
				@click:append="show_new_password = !show_new_password"
				:append-icon="
					$vuetify.icons.values[show_new_password ? 'eye' : 'eye_off']
				"
			>
			</v-text-field>

			<!-- Confirm Password -->
			<div class="label-wr">{{ $t("app.confirm_password") }}</div>
			<v-text-field
				outlined
				color="greenAccent2"
				background-color="white"
				:rules="[rules.required, rules.strongPassword]"
				v-model="confirm_password"
				:type="show_confirm_password ? 'text' : 'password'"
				@click:append="show_confirm_password = !show_confirm_password"
				:append-icon="
					$vuetify.icons.values[show_confirm_password ? 'eye' : 'eye_off']
				"
			>
			</v-text-field>
		</v-form>

		<v-card-actions>
			<AppButton
				label="app.save"
				icon="save"
				:class="['mt-3']"
				@click="validateAndSubmit()"
			></AppButton>
		</v-card-actions>
	</v-card>
</template>

<script>
import rules from "@/utils/validation";

export default {
	name: "ResetPassword",

	data() {
		return {
			rules,
			new_password: null,
			show_new_password: false,
			confirm_password: null,
			show_confirm_password: false,
		};
	},

	methods: {
		async validateAndSubmit() {
			try {
				this.$loader.start();

				// Validate the form
				if (!this.$refs.form.validate()) {
					throw new Error("app.ntfy.err.fill_required_fields", {
						cause: "werCustom",
					});
				}

				if (this.confirm_password !== this.new_password) {
					throw new Error("app.ntfy.err.password_not_match", {
						cause: "werCustom",
					});
				}

				// Reset password
				await this.$store.dispatch("user/resetPassword", {
					new_password: this.new_password,
				});

				this.$refs.form.reset();

				this.$announce.success("app.ntfy.succ.password_reset");

				this.$emit("close");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
