<template>
	<v-card flat>
		<v-form ref="form">
			<!-- First name -->
			<div>
				<div class="label-wr">{{ $t("app.first_name") }}</div>
				<v-text-field
					v-model="user.first_name"
					outlined
					color="greenAccent2"
					readonly
				></v-text-field>
			</div>

			<!-- Last name -->
			<div>
				<div class="label-wr">{{ $t("app.last_name") }}</div>
				<v-text-field
					v-model="user.last_name"
					outlined
					color="greenAccent2"
					background-color="white"
					readonly
				></v-text-field>
			</div>

			<!-- Username -->
			<div>
				<div class="label-wr">{{ $t("app.placeholder.username") }}</div>
				<v-text-field
					v-model="user.username"
					outlined
					color="greenAccent2"
					background-color="white"
					readonly
				></v-text-field>
			</div>

			<!-- Email address -->
			<div>
				<div class="label-wr">{{ $t("app.email_address") }}</div>
				<v-text-field
					v-model="user.email"
					outlined
					color="greenAccent2"
					background-color="white"
					readonly
				></v-text-field>
			</div>
		</v-form>
	</v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "UserPersonalInfo",

	computed: {
		...mapGetters({
			user: "user/getPersonalDetails",
		}),
	},
};
</script>
